export enum AppManagementProfileMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum AppManagementStatus {
  MANAGED = 'MANAGED',
  DENIED = 'DENIED',
  TRUSTED = 'TRUSTED',
}
