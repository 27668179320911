import { useEffect } from 'react';
import { socket } from './rt';

export enum Events {
  INTUNE_SYNC_SUCCESSFUL = 'intune-sync-success',
}

export interface EventDataType {
  name: string;
  message?: string;
  description?: string;
  sender?: string;
}

// A simple pub-sub to subscribe and publish events
export const QuokkaEvent = {
  list: new Map(),
  on(eventType: Events, callback) {
    if (!this.list.has(eventType)) {
      this.list.set(eventType, []);
    }
    if (this.list.get(eventType)) this.list.get(eventType).push(callback);
    return this;
  },

  emit(eventType: Events, args: EventDataType) {
    const callbacks = this.list.get(eventType);
    if (callbacks) {
      callbacks.forEach((cb: (args: EventDataType) => void) => {
        cb(args);
      });
    }
  },
};

export const useSubscribeToEvent = (eventType: Events) => {
  function onEvent(content) {
    QuokkaEvent.emit(eventType, content);
  }

  useEffect(() => {
    socket.on('app-event', onEvent);

    return () => {
      socket.off('app-event', onEvent);
    };
  }, [eventType]);
};
