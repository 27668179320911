import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ManagedAppsInfoContext } from './AppManagementInfoContext';
import { formatPlatform } from '../../utils/formatPlatform';
// eslint-disable-next-line import/extensions
import { AppManagementStatus } from './enum/AppManagementKeyMaps.enum';

export const AppManagementSearchHeader = () => {
  const location = useLocation();
  const { platform, appManagementStatus } = useContext(ManagedAppsInfoContext);
  const pageAction = location.pathname.includes('edit') ? 'Edit' : 'Add';
  const searchPlatform = formatPlatform(platform || 'iOS');

  return (
    <>
      <StyledHeaderText>
        {(() => {
          switch (appManagementStatus) {
            case AppManagementStatus.MANAGED:
              return 'Managed Apps / ';
            case AppManagementStatus.TRUSTED:
              return 'Trusted Apps / ';
            case AppManagementStatus.DENIED:
              return 'Denied Apps / ';
            default:
              return '';
          }
        })()}
        {pageAction} {searchPlatform} app
      </StyledHeaderText>
    </>
  );
};

const StyledHeaderText = styled(Typography)`
  flex-grow: 1;
  line-height: 45px;
  color: ${props => props.theme.palette.text.secondary};
  // shorten leading trim on leftside to offset default whitespace
  margin-left: -4px;
` as typeof Typography;

// @ts-ignore
StyledHeaderText.defaultProps = {
  component: 'h1',
  variant: 'h1Regular',
};
