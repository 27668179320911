import { ErrorOutline, Close } from '@mui/icons-material';
import { ListItem, IconButton, ListItemText, Typography, styled, Card, Link, Input } from '@mui/material';
import { RuleEntity } from '@kw/service-definitions-rule';
import { Dispatch, SetStateAction, useContext } from 'react';
import type { PolicyAction } from './getData';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
import { PolicyInfoContext } from './PolicyInfoContext';
import { RuleNameAndCategoryBadge } from './PolicyListCard';

interface IProps<T> {
  selectedList: T[];
  originalList: T[];
  title: string;
  removeAction: (id: number | string) => void;
  isHighlight: boolean;
  mode: PolicyProfileMode;
  handleEdit?: Dispatch<PolicyAction>;
}

export const PolicySelectedListCard = ({
  selectedList = [],
  originalList = [],
  title = '',
  removeAction,
  isHighlight = false,
  mode,
  handleEdit,
}: IProps<RuleEntity | PolicyAction>) => {
  const { policyFormErrors, policyRuleParameters, setPolicyRuleParameters } = useContext(PolicyInfoContext);
  const { maliciousThreshold } = policyRuleParameters;

  const FullListNotice = () => {
    if (originalList.every(originalItem => selectedList.find(selectedItem => selectedItem.id === originalItem.id))) {
      return (
        <StyledNotice>
          <ErrorOutline /> All {title} added
        </StyledNotice>
      );
    }

    return <></>;
  };

  const saveInstructions = title === 'rules' && 'Include at least one rule';

  const ListCard = () => {
    const content =
      selectedList.length === 0 ? (
        <StyledTextWrapper>
          <Typography variant="h5Medium" data-testid={`selected-${title}-card`}>
            Select {title} that will be added to this policy
          </Typography>
          <Typography variant="subtitle1">{saveInstructions}</Typography>
        </StyledTextWrapper>
      ) : (
        selectedList.map(item => {
          const category = 'skuSetting' in item && item.skuSetting === 'app-rules' ? 'App' : 'Device';
          return (
            <StyledListItem
              key={item.id}
              secondaryAction={
                [PolicyProfileMode.CREATE, PolicyProfileMode.EDIT].includes(mode) ? (
                  <>
                    {(item as PolicyAction).emails ? (
                      <StyledLink
                        onClick={() => {
                          handleEdit(item as PolicyAction);
                        }}
                      >
                        Edit
                      </StyledLink>
                    ) : (item as RuleEntity).name === 'Device has malicious app' ? (
                      <MaliciousThresholdInputWrapper>
                        <StyledInput
                          type="number"
                          defaultValue={maliciousThreshold}
                          placeholder={String(maliciousThreshold)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPolicyRuleParameters({ ...policyRuleParameters, maliciousThreshold: Number(event.target.value) });
                            // this is hard to edit with on change event
                          }}
                          inputProps={{ min: 0, max: 100 }}
                        />
                        <StyledInputCaption variant="caption">Maximum of 100</StyledInputCaption>
                      </MaliciousThresholdInputWrapper>
                    ) : null}
                    <IconButton edge="end" aria-label="remove" onClick={() => removeAction(item.id)}>
                      <Close />
                    </IconButton>
                  </>
                ) : [PolicyProfileMode.VIEW].includes(mode) && (item as RuleEntity).name === 'Device has malicious app' ? (
                  <MaliciousThresholdViewModeWrapper>
                    <Typography variant="bodyLight">{String(maliciousThreshold)}</Typography>
                  </MaliciousThresholdViewModeWrapper>
                ) : null
              }
              divider
              data-testid={`selected-${title}-item`}
            >
              <StyledListItemText
                primary={<RuleNameAndCategoryBadge name={item.name} category={category} />}
                secondary={(item as PolicyAction).emails?.length ? (item as PolicyAction).emails.join(', ').trim() : item.description}
              />
            </StyledListItem>
          );
        })
      );

    let component;

    if (selectedList.length === 0) {
      component = (
        <>
          <StyledEmptyCard variant="outlined" className={title === 'rules' && policyFormErrors.rules ? 'error' : ''}>
            {content}
          </StyledEmptyCard>
          <KwFormErrorMessage
            error={title === 'rules' && policyFormErrors.rules}
            reason="Unable to save policy. Policy must include at least one rule."
          />
        </>
      );
    } else {
      component = <StyledFilledCard>{content}</StyledFilledCard>;
    }

    if (isHighlight) {
      component = (
        <>
          <StyledHighlightCard variant="outlined" className={title === 'rules' && policyFormErrors.rules ? 'error' : ''}>
            {content}
          </StyledHighlightCard>
          <KwFormErrorMessage
            error={title === 'rules' && policyFormErrors.rules}
            reason="Unable to save policy. Policy must include at least one rule."
          />
        </>
      );
    }

    return component;
  };

  return (
    <>
      <FullListNotice />
      <ListCard />
    </>
  );
};

const StyledListItemText = styled(ListItemText)({
  maxWidth: 'calc(100% - 48px)',
  marginRight: '200px',
});

const StyledEmptyCard = styled(Card)({
  borderRadius: '8px',
  textAlign: 'center',
  border: '1px solid #979797',
  '&.error': {
    border: '2px solid #f44336',
  },
});

const StyledFilledCard = styled(StyledEmptyCard)({
  border: '2px solid #FAFAFA',
  boxShadow: '0 10px 14px 0 rgba(0,0,0,0.04)',
});

const StyledHighlightCard = styled(StyledEmptyCard)({
  border: '2px solid #487F87',
  '&.error': {
    border: '2px solid #f44336',
  },
});

const StyledListItem = styled(ListItem)({
  backgroundColor: '#FAFAFA',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const StyledNotice = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const StyledTextWrapper = styled('div')({
  padding: '75px 0',
});

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  margin: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(1) {
    color: #487f87;
  }
`;

const StyledInput = styled(Input)`
  &:before {
    border-bottom: none;
  }
  padding: 2px 24px;
  margin: 0 20px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
`;

const StyledInputCaption = styled(Typography)`
  display: block;
  text-align: center;
`;

// CREATE / EDIT
const MaliciousThresholdInputWrapper = styled('div')`
  float: left;
`;

// VIEW
const MaliciousThresholdViewModeWrapper = styled('div')`
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 2px 12px;
  float: left;
`;
