import { Checkbox, Divider, List, ListItem, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../../utils/network.service';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  app: any; // replace
  category: string;
  mutateManagedAppsData: () => void;
  trustedApps: { bundleId: string; os: string }[];
  mutateTrustedAppsData: () => void;
}

export const ManagedAppsModal = ({ isOpen, onClose, app, category, mutateManagedAppsData, trustedApps, mutateTrustedAppsData }: IProps) => {
  const [keepTrustedAppChecked, setkeepTrustedAppChecked] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const removeManagedApp = async () => {
    await sendHttpRequest({
      path: `${process.env.PDM_SERVICE_URL}/pdm/managed-apps/${app.managedAppId}`,
      method: 'DELETE',
      successMessage: `Successfully removed ${app.name}`,
      errorMessage: `Error removing ${app.name}`,
      enqueueSnackbar,
    });
    mutateManagedAppsData();
    onClose();
  };

  const addTrustedApp = async () => {
    const { name, packageName, platform, vendorName } = app;

    const body = {
      bundleId: packageName,
      os: platform,
      name,
      vendorName,
    };

    const successMessage = `Successfully added ${name} as a Trusted App`;
    const errorMessage = `Error adding ${name} as a Trusted App`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps`,
      method: 'POST',
      body,
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });
  };

  const removeTrustedApp = async () => {
    const { packageName, platform } = app;

    const successMessage = `Successfully removed ${app.name} from Trusted Apps`;
    const errorMessage = `Error removed ${app.name} from Trusted Apps`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps/${packageName}/${platform}`,
      method: 'DELETE',
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });
  };

  const publishManagedApp = async () => {
    const { packageName, platform, published } = app;
    await sendHttpRequest({
      path: `${process.env.PDM_SERVICE_URL}/pdm/managed-apps/${app.managedAppId}`,
      method: 'PUT',
      body: { published: !published, packageName, platform },
      successMessage: `Successfully ${!published ? 'published' : 'unpublished'} ${app.name}`,
      errorMessage: `Error ${!published ? 'publishing' : 'unpublishing'} ${app.name}`,
      enqueueSnackbar,
    });
    mutateManagedAppsData();

    if (published && !keepTrustedAppChecked) {
      // if app is being unpublished and checkbox is not checked, remove it from the trusted apps list
      await removeTrustedApp();
      mutateTrustedAppsData();
    } else if (!published) {
      // if app is being published, add it to the trusted apps list
      await addTrustedApp();
      mutateTrustedAppsData();
    }

    onClose();
  };

  const appName = app.name;
  const text = {
    remove: {
      header: `Remove ${appName}?`,
      subheader: `What happens when you remove ${appName}:`,
      list: [
        `${appName} will be unpublished and deleted from your list of Managed Apps`,
        `${appName} will be removed from any Device that has it installed`,
        `${appName} will no longer show up as an available Managed App in the Q-Scout Mobile app for enrolled Devices`,
      ],
      cta: 'Remove app',
    },
    publish: {
      header: `Publish ${appName}?`,
      subheader: `What happens when you publish ${appName}:`,
      list: [
        `${appName} will show up as an available Managed App in the Q-Scout Mobile app for enrolled Devices`,
        `As Device Owners install the app, the # of Devices count should grow`,
        `Device Owners will be offered the latest version of the app available from the App Store`,
        `${appName} will be added to the Trusted Apps list`,
      ],
      cta: 'Publish app',
    },
    unpublish: {
      header: `Unpublish ${appName}?`,
      subheader: `What happens when you unpublish ${appName}:`,
      list: [
        `${appName} will be removed from any Device that has it installed`,
        `${appName} will no longer show up as an available Managed App in the Q-Scout mobile app for enrolled Devices`,
      ],
      cta: 'Unpublish app',
    },
  };

  const modalText = text[category];
  const { header, subheader, list, cta } = modalText;

  const appInTrustedList = trustedApps?.find(trustedApp => trustedApp.bundleId === app.packageName && trustedApp.os === app.platform);
  const RemoveFromTrustedListCheckBox = () => {
    return (
      <>
        <StyledDivider />
        <Checkbox
          checked={keepTrustedAppChecked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setkeepTrustedAppChecked(event.target.checked);
          }}
        />
        <StyledCheckboxText>
          <Typography variant="bodyRegular">Keep {appName} in Trusted Apps List.</Typography>
          <StyledGrayText variant="bodyRegular">You can change this at anytime in the Trusted Apps tab</StyledGrayText>
        </StyledCheckboxText>
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledBox>
        <Typography variant="h3Medium" id={`${category}-managed-app-modal-title`}>
          <StyledCloseIcon onClick={onClose} />
          {header}
        </Typography>
        <StyledDivider />
        <StyledTypography variant="bodyRegular">{subheader}</StyledTypography>
        <StyledTypography variant="bodyRegular">
          <StyledList>
            {list.map((listitem: string, index: number) => {
              return <StyledListItem key={`${category}-list-item-${index}`}>{listitem}</StyledListItem>;
            })}
          </StyledList>
        </StyledTypography>

        {appInTrustedList && cta === text.unpublish.cta && <RemoveFromTrustedListCheckBox />}

        <StyledButtonsContainer>
          <KwButton variant="outlined" onClick={onClose}>
            Cancel
          </KwButton>
          <KwButton variant="filled" onClick={category === 'remove' ? removeManagedApp : publishManagedApp}>
            {cta}
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fafafa;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const StyledGrayText = styled(Typography)`
  color: #757575;
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  & button {
    margin-left: 24px;
  }
`;

const StyledCheckboxText = styled('div')`
  display: inline-grid;
  margin-bottom: 25px;
`;

const StyledList = styled(List)`
  list-style-type: disc;
  margin-bottom: 10px;
`;

const StyledListItem = styled(ListItem)`
  display: list-item;
  margin-left: 16px;
  padding-left: 0;
`;
