import { Avatar, IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from '../../../theme';
// components
import { KwSearchInput } from '../../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../../kw-ui-components/KwTable';
import { KwTableBody } from '../../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../../kw-ui-components/KwTableCell';
import { KwTableRow } from '../../../kw-ui-components/KwTableRow';
// pagination
import { KwPaginationRouter } from '../../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../../kw-ui-components/Pagination/useKwPagination';
import { KwTablePaginationWrapper } from '../../../kw-ui-components/Pagination/KwTablePaginationWrapper';

import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';
import { KwTableHead } from '../../../kw-ui-components/KwTableHead';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { KwColumnSortHeader } from '../../../kw-ui-components/KwColumnSortHeader';

import { useFetchService } from '../../../utils/fetchService';
import { useAppManagementSortParams } from '../useAppManagementSortParams';
import { PageLoader } from '../../../PageLoader';
import { KwMultiSelect, useKwMultiSelect } from '../../../kw-ui-components/KwMultiSelect/KwMultiSelect';
import { ResetButton } from '../../shared/ResetButton';
import { getComparator } from '../../../utils/getComparator';
import { createQueryString } from '../../../utils/createQueryString';
import { formatPlatform } from '../../../utils/formatPlatform';
import { ManagedAppsTableAddAppPopover, AppManagementTableMorePopover } from '../AppManagementPopovers';
import { ManagedAppsModal } from './ManagedAppsModals';

export const useManagedApps = (queryParams, accessToken) => {
  const queryParamString = createQueryString(queryParams);
  const { data: managedAppsData, error: managedAppsError, mutate } = useFetchService(`/pdm/managed-apps?${queryParamString}`, accessToken);
  const isLoading = !managedAppsData && !managedAppsError;
  return { managedAppsData, managedAppsError, isLoading, mutate };
};

export const useTrustedApps = accessToken => {
  const { data: trustedAppsData, error: trustedAppsError, mutate } = useFetchService(`/list/trusted-apps`, accessToken);
  const isLoading = !trustedAppsData && !trustedAppsError;
  return { trustedAppsData, trustedAppsError, isLoading, mutate };
};

export default function ManagedAppsTable() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [vendorData, setVendorData] = useState([]);
  const [appId, setAppId] = useState(null);
  const [moreButtonAnchorEl, setMoreButtonAnchorEl] = useState(null);
  const [addAppButtonAnchorEl, setAddAppButtonAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalAppInfo, setModalAppInfo] = useState({});
  const [modalCategory, setModalCategory] = useState('remove');
  const { page, pageSize, handleChangePage, handleChangeRowsPerPage, setPage } = useKwPagination(10);
  const {
    appNameOrder,
    toggleAppNameOrder,
    platformOrder,
    togglePlatformOrder,
    vendorOrder,
    toggleVendorOrder,
    versionOrder,
    toggleVersionOrder,
    appIdOrder,
    toggleAppIdOrder,
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder,
    publishedOrder,
    togglePublishedOrder,
    orderBy,
    setOrderBy,
    resetToggles,
  } = useAppManagementSortParams();

  const platformSelectHook = useKwMultiSelect();
  const vendorSelectHook = useKwMultiSelect();
  const publishedSelectHook = useKwMultiSelect();

  // eslint-disable-next-line prefer-const
  let { managedAppsData, managedAppsError, isLoading, mutate } = useManagedApps(
    {
      os: platformSelectHook.value.length > 1 ? '' : platformSelectHook.value,
    },
    accessToken,
  );

  const { trustedAppsData, mutate: mutateTrustedAppsData } = useTrustedApps(accessToken);

  const filteredManagedApps = useMemo(() => {
    const apps = managedAppsData || [];
    const query = searchQuery.toLowerCase();
    const filterConditions = searchQuery.length || vendorSelectHook.value.length || publishedSelectHook.value.length;

    return filterConditions
      ? apps
          .filter(
            item =>
              item.name.toLowerCase().includes(query) ||
              item.platform.toLowerCase().includes(query) ||
              item.vendorName.toLowerCase().includes(query) ||
              item.version.toLowerCase().includes(query) ||
              item.packageName.toLowerCase().includes(query),
          )
          .filter(item => {
            if (vendorSelectHook.value.length && publishedSelectHook.value.length) {
              return vendorSelectHook.value.includes(item.vendorName) && publishedSelectHook.value.includes(String(item.published));
            }
            if (vendorSelectHook.value.length) {
              return vendorSelectHook.value.includes(item.vendorName);
            }
            if (publishedSelectHook.value.length) {
              return publishedSelectHook.value.includes(String(item.published));
            }
            return true;
          })
      : apps;
  }, [managedAppsData, searchQuery, publishedSelectHook.value, vendorSelectHook.value]);

  const total = filteredManagedApps?.length;
  const totalPageCount = Math.ceil(total / pageSize);

  const tableHeaders = [
    {
      title: 'App Name',
      onClick: toggleAppNameOrder,
      sortValue: appNameOrder,
    },
    {
      title: 'Platform',
      onClick: togglePlatformOrder,
      sortValue: platformOrder,
    },
    {
      title: 'Vendor',
      onClick: toggleVendorOrder,
      sortValue: vendorOrder,
    },
    {
      title: 'Version',
      onClick: toggleVersionOrder,
      sortValue: versionOrder,
    },
    {
      title: 'App ID',
      onClick: toggleAppIdOrder,
      sortValue: appIdOrder,
    },
    {
      title: '# of Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: 'Published',
      onClick: togglePublishedOrder,
      sortValue: publishedOrder,
    },
    {
      // kebab icon column
      title: '',
      onClick: () => {},
      sortValue: null,
    },
  ];

  // column sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (appNameOrder) {
      setOrderBy('name');
    }

    if (platformOrder) {
      setOrderBy('platform');
    }

    if (vendorOrder) {
      setOrderBy('vendorName');
    }

    if (versionOrder) {
      setOrderBy('version');
    }

    if (appIdOrder) {
      setOrderBy('packageName');
    }

    if (numberOfDevicesOrder) {
      setOrderBy('deviceInstallCount');
    }

    if (publishedOrder) {
      setOrderBy('published');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [
    numberOfDevicesOrder,
    order,
    orderBy,
    setOrderBy,
    appNameOrder,
    platformOrder,
    vendorOrder,
    versionOrder,
    appIdOrder,
    publishedOrder,
  ]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, setPage]);

  const platformSelectData = [
    { value: 'android', label: 'Android' },
    { value: 'ios', label: 'iOS' },
  ];

  const publishedSelectData = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ];

  const vendorSelectData = useRef([]);
  useEffect(() => {
    const appData = managedAppsData || [];
    vendorSelectData.current = appData.map(({ vendorName }) => ({ value: vendorName, label: vendorName }));
    setVendorData([...new Map(vendorSelectData.current.map(item => [item.value, item])).values()]);
  }, [filteredManagedApps, managedAppsData]);

  const resetFilterAndSorts = () => {
    resetToggles();
    setPage(0);
    platformSelectHook.reset();
    vendorSelectHook.reset();
    publishedSelectHook.reset();
    setSearchQuery('');
  };

  const handleMoreButtonPopoverClick = (
    event: { currentTarget: HTMLButtonElement | HTMLAnchorElement } = null,
    managedAppId: string = null,
  ) => {
    if (!event && !managedAppId) {
      setAppId(null);
      setMoreButtonAnchorEl(null);
    } else {
      setAppId(managedAppId);
      setMoreButtonAnchorEl(event.currentTarget);
    }
  };

  const handleAddAppPopoverClick = event => {
    setAddAppButtonAnchorEl(addAppButtonAnchorEl ? null : event.currentTarget);
  };

  if (managedAppsError) {
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <ThemeProvider theme={theme}>
        <StyledFilterWrapper>
          <KwMultiSelect
            label="Platform"
            items={platformSelectData}
            {...platformSelectHook}
            handleChange={(...args) => {
              platformSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwMultiSelect
            label="Vendor"
            items={vendorData}
            {...vendorSelectHook}
            handleChange={(...args) => {
              vendorSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwMultiSelect
            label="Published"
            items={publishedSelectData}
            {...publishedSelectHook}
            handleChange={(...args) => {
              publishedSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <ResetButton
            onClick={() => {
              resetFilterAndSorts();
            }}
          />
          <StyledKwButton endIcon={<KeyboardArrowDownIcon />} variant="filled" onClick={event => handleAddAppPopoverClick(event)}>
            Add Managed App
          </StyledKwButton>
          <ManagedAppsTableAddAppPopover
            anchorEl={addAppButtonAnchorEl}
            setAnchorEl={setAddAppButtonAnchorEl}
            handleClose={event => handleAddAppPopoverClick(event)}
          />
        </StyledFilterWrapper>
        <StyledTableWrapper>
          <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search apps" />
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>
          <KwTable aria-label="Managed-Apps-Table">
            <KwTableHead>
              <KwTableRow>
                {tableHeaders.map(({ title, onClick, sortValue }) => (
                  <KwColumnSortHeader
                    key={title}
                    title={title}
                    onClick={() => {
                      onClick();
                      setPage(0);
                    }}
                    sortValue={sortValue}
                  />
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {[...filteredManagedApps]
                .sort(getComparator(order, orderBy))
                .slice(page * pageSize, (page + 1) * pageSize)
                .map(app => {
                  return (
                    <KwTableRow key={app.managedAppId} aria-label={`${app.name} row`}>
                      <StyledKwTableCell>
                        <StyledAvatar alt={`${app.name} app icon`} src={app.iconUrl} />
                        {app.name}
                      </StyledKwTableCell>
                      <KwTableCell>{formatPlatform(app.platform)}</KwTableCell>
                      <KwTableCell>{app.vendorName}</KwTableCell>
                      <KwTableCell>{app.version}</KwTableCell>
                      <KwTableCell>{app.packageName}</KwTableCell>
                      <KwTableCell>{app.deviceInstallCount}</KwTableCell>
                      <KwTableCell>{app.published ? 'Yes' : 'No'}</KwTableCell>
                      <KwTableCell>
                        <IconButton size="medium" onClick={event => handleMoreButtonPopoverClick(event, app.managedAppId)}>
                          <MoreVertIcon />
                        </IconButton>
                      </KwTableCell>
                      <AppManagementTableMorePopover
                        app={app}
                        appId={appId}
                        anchorEl={moreButtonAnchorEl}
                        setAppId={setAppId}
                        setAnchorEl={setMoreButtonAnchorEl}
                        handleClose={() => handleMoreButtonPopoverClick()}
                        setShowModal={setShowModal}
                        setModalAppInfo={setModalAppInfo}
                        setModalCategory={setModalCategory}
                        mutate={mutate}
                      />
                    </KwTableRow>
                  );
                })}
              <ManagedAppsModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                app={modalAppInfo}
                category={modalCategory}
                mutateManagedAppsData={mutate}
                trustedApps={trustedAppsData?.trustedApps || []}
                mutateTrustedAppsData={mutateTrustedAppsData}
              />
            </KwTableBody>
          </KwTable>
          {total > 10 ? (
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
            </KwTablePaginationWrapper>
          ) : null}
        </StyledTableWrapper>
      </ThemeProvider>
    </>
  );
}

const StyledTableWrapper = styled('div')`
  width: 100%;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledKwButton = styled(KwButton)`
  // align-self: flex-end;
  margin-left: auto;
  min-width: 110px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;

const StyledKwTableCell = styled(KwTableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledFilterWrapper = styled('div')`
  display: flex;
  column-gap: 16px;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
`;
