import { styled } from '@mui/material/styles';
import { Fragment } from 'react';
import { transformAndCleanData } from '../utils/transformData';

const Grid = (data: any) => {
  const transformedData = transformAndCleanData(data.data.threatTypes);

  // Order the threat types to match what the Figma design shows
  const threatTypesOrdered = ['Adware', 'Dialer', 'Hacktool', 'Malware', 'PUA', 'Backdoor', 'Spyware', 'Trojan', 'Ransomware'];

  return (
    <StyledThreeColumnGrid>
      {threatTypesOrdered.map((threatType, idx) => (
        <div key={idx}>
          <div
            style={{
              display: 'inline-block',
              textAlign: 'right',
              marginRight: '5px',
              fontWeight: 'bold',
              marginBottom: '15px',
              width: '46px',
            }}
          >
            {transformedData[threatType]}
          </div>
          <div style={{ display: 'inline-block', textAlign: 'left' }}>{threatType}</div>
        </div>
      ))}
    </StyledThreeColumnGrid>
  );
};

export default Grid;

const StyledThreeColumnGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
`;
