import { useEffect, useRef, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, Tooltip, Legend, Filler, PointElement, LineElement } from 'chart.js';
import { ThreatTypeDistributionTooltip } from './ThreatTypeDistributionTooltip';

Chart.register(RadialLinearScale, Tooltip, Legend, Filler, PointElement, LineElement);

// Get the positions of the labels in the radar chart. This is used to position
// the tooltips (through ThreatTypeDistributionTooltips) and underline the labels (through the plugin underlineLabelsPlugin).
function getLabelsPositions(chart: any) {
  const { data } = chart.config;
  const scale = chart.scales.r;
  const labelPositions = data.labels.map((label, index) => {
    const point = scale.getPointLabelPosition(index);

    return { label, point };
  });

  return labelPositions;
}

interface IProps {
  tooltipsText?: { [key: string]: string };
  gradientThreshold?: { low?: number; medium?: number; high?: number };
  data: {
    labels: string[];
    datasets: { label: string; data: number[]; borderWidth: number; pointBackgroundColor: (context: any) => string }[];
  };
  options?: any;
}

export const ThreatTypeDistributionRadar = (props: IProps) => {
  const [labelPositionsState, setLabelPositionsState] = useState([]);
  const chartRef = useRef(null);

  const radarOptions = {
    aspectRatio: 1,
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      r: {
        min: -10,
        max: 100,
        ticks: {
          stepSize: 25,
          display: false,
        },
        grid: {
          circular: true,
        },
        angleLines: {
          display: true,
        },
        pointLabels: {
          font: {
            size: '14vw',
            weight: 'bold',
          },
          padding: 20,
        },
      },
    },
    elements: {
      line: {
        fill: true,
      },
      point: {
        radius: 5,
      },
    },
  };

  const radialGradientPlugin = {
    id: 'radialGradientPlugin',
    beforeDraw(chart) {
      const {
        ctx,
        scales: { r: scale },
      } = chart;

      const metadata = chart.getDatasetMeta(0);
      const { xCenter, yCenter, drawingArea: radius } = scale;

      const gradient = ctx.createRadialGradient(xCenter, yCenter, 0, xCenter, yCenter, radius);

      const { low = 0, medium = 0.4, high = 1 } = props?.gradientThreshold || {};

      gradient.addColorStop(low, 'rgba(33, 150, 243, 0.3)'); // Blue
      gradient.addColorStop(medium, 'rgba(255, 152, 0, 0.3)'); // Orange
      gradient.addColorStop(high, 'rgba(244, 67, 54, 0.3)'); // Red

      ctx.save();
      ctx.beginPath();
      const firstValue = metadata.data[0];
      ctx.moveTo(firstValue.x, firstValue.y);

      metadata.data.forEach(value => {
        const { x, y } = value;
        ctx.lineTo(x, y);
      });
      ctx.closePath();

      metadata.data.forEach(value => {
        const { x, y } = value;
        ctx.moveTo(x, y);
        ctx.arc(x, y, 5, 0, Math.PI * 2);
      });
      ctx.fillStyle = gradient;
      ctx.fill();
      ctx.restore();
    },
  };

  const radialGradientBackgroundPlugin = {
    id: 'radialGradientBackgroundPlugin',
    beforeDraw(chart) {
      const {
        ctx,
        scales: { r: scale },
      } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scale;

      const areaGradient = ctx.createRadialGradient(xCenter, yCenter, 0, xCenter, yCenter, radius * 1.2);

      ctx.save();
      ctx.arc(xCenter, yCenter, radius * 1.5, 0, Math.PI * 2);
      ctx.fillStyle = areaGradient;
      ctx.fill();
      ctx.restore();
    },
  };

  const underlineLabelsPlugin = {
    id: 'underlineLabelsPlugin',
    beforeDraw(chart) {
      const labelPositions = getLabelsPositions(chart);

      const { ctx } = chart;
      ctx.save();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      labelPositions.forEach(({ label, point }, index) => {
        ctx.beginPath();
        ctx.moveTo(point.left, point.bottom);
        ctx.lineTo(point.right, point.bottom);
        ctx.stroke();
      });
      ctx.restore();
    },
  };

  // Update the label positions state when the component is mounted. This is used to position the tooltips.
  // TODO: this effect causes the component to re-render twice. This maybe can be optimized.
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const labelPositions = getLabelsPositions(chart);

    const positionsWithTooltipText = [];

    for (let i = 0; i < labelPositions.length; i++) {
      const { point } = labelPositions[i];
      positionsWithTooltipText.push({
        label: labelPositions[i].label,
        point: {
          top: point.top,
          bottom: point.bottom,
          left: point.left,
          right: point.right,
        },
        text: props.tooltipsText[labelPositions[i].label],
      });
    }

    setLabelPositionsState(positionsWithTooltipText);
  }, []);

  const updatedProps = {
    data: {
      ...props?.data,
    },
    options: {
      ...radarOptions,
      ...props?.options,
    },
    plugins: [radialGradientBackgroundPlugin, radialGradientPlugin, underlineLabelsPlugin],
  };

  return (
    <>
      <Radar {...updatedProps} ref={chartRef} redraw={true}></Radar>
      {labelPositionsState.map(({ label, point, text }, index) => (
        <ThreatTypeDistributionTooltip key={index} text={text} position={point}></ThreatTypeDistributionTooltip>
      ))}
    </>
  );
};
