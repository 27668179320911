import { QTooltip, QTooltipContent } from '@kw/quokka-ui/dist/cjs';
import { Stack } from '@mui/system';
import { useState } from 'react';

// Displays a tooltip with the given text at the given position
export const ThreatTypeDistributionTooltip = ({ text, position }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <QTooltip
        title={<QTooltipContent subheadline={<Stack style={{ textAlign: 'left' }}>{text}</Stack>} />}
        placement="top"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      >
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: position.top,
            left: position.left,
            width: position.right - position.left,
            height: position.bottom - position.top,
          }}
        ></div>
      </QTooltip>
    </>
  );
};
