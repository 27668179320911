import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { Box, Divider, Modal, styled, Typography } from '@mui/material';
import { sendHttpRequest } from '../../../utils/network.service';
import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';

export const RemoveTrustedAppConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  selectedApp,
  fetchTrustedApps,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveTrustedApp = async (applicationId: string, os: string, name: string, vendorName: string) => {
    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps/${applicationId}/${os}`,
      method: 'DELETE',
      successMessage: `Successfully removed ${name} from Trusted Apps`,
      errorMessage: `Error removing ${name} from Trusted Apps`,
      enqueueSnackbar,
    });

    fetchTrustedApps();
  };

  return (
    <Modal
      open={showConfirmationModal}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      onClose={() => setShowConfirmationModal(false)}
      onClick={e => e.stopPropagation()}
    >
      <StyledBox>
        <Typography variant="h3Medium" id="confirmation-modal-title">
          Remove from Trusted Apps?
        </Typography>

        <StyledDivider />

        <div id="confirmation-modal-description">
          <Typography variant="bodyRegular">
            {selectedApp.name} will now trigger any high-level Issues and/or Policy Violations found from analysis.
          </Typography>
        </div>

        <StyledButtonsContainer>
          <KwButton onClick={() => setShowConfirmationModal(false)}>Cancel</KwButton>
          <KwButton
            variant="filled"
            onClick={() => {
              handleRemoveTrustedApp(selectedApp.applicationId, selectedApp.os, selectedApp.name, selectedApp.vendorName);
              setShowConfirmationModal(false);
            }}
          >
            Remove from Trusted Apps
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

interface IProps {
  showConfirmationModal: boolean;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  selectedApp: { applicationId: string; os: string; name: string; vendorName: string };
  fetchTrustedApps: () => void;
}

const StyledBox = styled(Box)(() => ({
  padding: 16,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  backgroundColor: '#fafafa',
}));

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
});

const StyledButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 8,
});
