import { styled } from '@mui/material/styles';
import { MaliciousScoreGauge } from './MaliciousScoreGauge';
import Grid from './MaliciousScoreGrid';

export const MaliciousScoreChart = ({ results }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledTitle>Malicious Score</StyledTitle>

        <div>
          <MaliciousScoreGauge threatLevel={results[0]?.properties?.threatLevel} />
        </div>
        <div style={{ margin: '0 auto', minWidth: '433px' }}>
          <StyledFactorsTitle>Malicious Score Factors</StyledFactorsTitle>
          <Grid
            data={{
              threatTypes: results[0]?.properties?.threatTypes,
            }}
          />
        </div>
      </div>
    </>
  );
};

const StyledTitle = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #000;
  margin: 0 0 20px 0;
`;

const StyledFactorsTitle = styled('div')`
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 5px;
  margin: 20px auto;
  text-align: center;
  font-size: 18px;
`;
