import { MaliciousScoreDoughnut } from './MaliciousScoreDoughnut';

export const MaliciousScoreGauge = ({ threatLevel }: { threatLevel: number | undefined }) => {
  const maliciousScoreFloat = threatLevel || 0;

  let color = '#ddd';
  if (maliciousScoreFloat >= 70) {
    color = '#F44336';
  } else if (maliciousScoreFloat >= 40) {
    color = '#FF9800';
  } else {
    color = '#2196F3';
  }

  const displayText = maliciousScoreFloat < 40 ? 'Benign' : maliciousScoreFloat < 70 ? 'Suspicious' : `Malicious`;

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <MaliciousScoreDoughnut
          text={displayText}
          value={maliciousScoreFloat}
          data={{
            datasets: [
              {
                data:
                  maliciousScoreFloat === -1
                    ? [1, 0] // Neutral or no data scenario
                    : [maliciousScoreFloat, 100 - maliciousScoreFloat],
                backgroundColor:
                  maliciousScoreFloat === -1
                    ? ['#E0E0E0', '#E0E0E0'] // Gray color for "No Data" case
                    : [color, '#E0E0E0'],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            circumference: 180,
            rotation: -90,
            animation: {
              animateRotate: false,
            },
            responsive: true,
            cutout: '80%',
            plugins: {
              tooltip: {
                enabled: false,
              },
              title: {
                display: true,
                text: 'Malicious Score',
                padding: { top: 10 },
                font: {
                  size: 14,
                  weight: 600,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
