import { Doughnut } from 'react-chartjs-2';

// The Background Doughnut that is placed behind the Malicious Score Doughnut. It displays three colors in a 180 degree arc.
// Blue from 0 to 39, Orange from 40 to 69, and Red from 70 to 100.
export const BackgroundDoughnut = props => {
  const data = {
    datasets: [
      {
        data: [39, 30, 31],
        backgroundColor: ['rgba(33, 150, 243, 1)', 'rgba(255, 152, 0, 1)', 'rgba(244, 67, 54, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    aspectRatio: 2,
    animation: {
      animateRotate: false,
    },
    responsive: true,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return <Doughnut {...props} data={data} options={options} />;
};
