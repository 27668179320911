import { styled } from '@mui/material/styles';
import { MouseEvent, useEffect, useState } from 'react';
import { Avatar, css, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import DeniedIcon from '@mui/icons-material/GppBad';
import TrustedIcon from '@mui/icons-material/GppGood';
import { Android, Apple, CalendarToday, MoreVert as MoreVertIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { ApplicationVersion } from '@kw/device-service/dist/application/entities/applicationVersion.entity';
import { TableRowLink } from '../shared/TableRowLink';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { PageLoader } from '../../PageLoader';
import { fetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import CountriesContactedCellContent from './CountriesContactedCellContent';
import { ApplicationsPopover } from './ApplicationsPopover';
import { useOrganization } from '../Settings/SettingsDropDownMenu';

type SortOrder = 'ASC' | 'DESC' | null;
interface IProps {
  apps: (ApplicationVersion & {
    applicationVersion: string;
    icon: string;
    importantFindings: boolean;
    management: string;
    permissions: number;
    policyViolations: boolean;
    policyIds: string[];
    riskIndicatorCount: number;
    title: string;
    versions: { deviceCount: number; deviceIds: string[]; version: string }[];
  })[];
  total: number;
  appsDataError: boolean;
  isLoading: boolean;
  highRiskCountries: string[];
  highRiskCountriesError: boolean;
  searchQuery: string;
  setSearchQuery: React.Dispatch<string>;
  appNameOrder: SortOrder;
  toggleAppNameOrder: () => void;
  numberOfIorsOrder: SortOrder;
  toggleNumberOfIorsOrder: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<number>;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ApplicationsTable({
  apps,
  total,
  appsDataError,
  isLoading,
  highRiskCountries,
  highRiskCountriesError,
  searchQuery,
  setSearchQuery,
  appNameOrder,
  toggleAppNameOrder,
  numberOfIorsOrder,
  toggleNumberOfIorsOrder,
  page,
  pageSize,
  setPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: IProps) {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { orgData, organizationError, isLoading: isLoadingOrg } = useOrganization(accessToken);
  const [popoverId, setPopoverId] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [deniedApps, setDeniedApps] = useState([]);
  const [trustedApps, setTrustedApps] = useState([]);

  const fetchDeniedApps = async () => {
    try {
      const response = await fetchService('/list/denied-apps', accessToken);
      setDeniedApps(response.deniedApps);
    } catch (error) {
      console.error('Error fetching denied apps', error);
    }
  };

  const fetchTrustedApps = async () => {
    try {
      const response = await fetchService('/list/trusted-apps', accessToken);
      setTrustedApps(response.trustedApps);
    } catch (error) {
      console.error('Error fetching trusted apps', error);
    }
  };

  useEffect(() => {
    fetchDeniedApps();
    fetchTrustedApps();
  }, [accessToken]);

  const showDeniedApps = orgData?.settings?.includes('denied-apps');
  const tableHeaders = [
    { title: 'App Name', onClick: toggleAppNameOrder, sortValue: appNameOrder },
    { title: 'App Type', onClick: () => {}, sortValue: null },
    // { title: 'Devices', onClick: () => {}, sortValue: null },
    { title: 'Countries Contacted', onClick: () => {}, sortValue: null },
    {
      title: 'Issues',
      onClick: toggleNumberOfIorsOrder,
      sortValue: numberOfIorsOrder,
    },
    { title: 'Permissions', onClick: () => {}, sortValue: null },
    { title: 'Actions', onClick: () => {}, sortValue: null },
  ];

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>, applicationId: string, os: string) => {
    setPopoverId(applicationId);
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setPopoverAnchorEl(null);
  };

  const totalPageCount = Math.ceil(total / pageSize);

  const platformIcon = (os: string) => (os === 'ios' ? <StyledAppleIcon /> : <StyledAndroidIcon />);

  const sumDeviceCounts = (versions: { version: string; deviceCount: number }[]) => {
    let totalCount = 0;
    for (const version of versions) {
      totalCount += version.deviceCount;
    }
    return totalCount;
  };

  const renderAppTag = (isDenied, isAllowed, policyViolations, importantFindings) => {
    if (isDenied) {
      return (
        <span>
          <StyledDeniedIcon color="error" />
          <StyledWarningMessage>Denied App</StyledWarningMessage>
        </span>
      );
    }
    if (isAllowed) {
      return (
        <>
          <StyledTrustedIcon color="success" />
          <StyledTrustedTagText>Trusted App</StyledTrustedTagText>
        </>
      );
    }
    if (policyViolations) {
      return (
        <span>
          <StyledWarningIcon color="error" />
          <StyledWarningMessage>Policy Violation</StyledWarningMessage>
        </span>
      );
    }
    if (importantFindings) {
      return (
        <span>
          <StyledWarningIcon color="error" />
          <StyledWarningMessage>Important Findings</StyledWarningMessage>
        </span>
      );
    }
    return null;
  };

  const appTypes = {
    MANAGED: 'Managed',
    DISCOVERED: 'Discovered',
  };

  const sumPreviousVersions = (
    currentVersion: string,
    otherVersions: { version: string; analyzedVersion: string; deviceCount: number }[] | null,
  ) => {
    if (!otherVersions) {
      return 0;
    }

    const totalVersions = new Set();

    otherVersions.forEach(v => {
      if (v.analyzedVersion && v.analyzedVersion !== currentVersion) {
        totalVersions.add(v.analyzedVersion);
      }
    });

    return totalVersions.size;
  };

  if (appsDataError) {
    console.error(appsDataError);
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <div>
        <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search applications" />
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
        </KwTablePaginationWrapper>
        <KwTable aria-label="Applications-Table">
          <KwTableHead>
            <KwTableRow>
              {tableHeaders.map(({ title, onClick, sortValue }) => (
                <KwColumnSortHeader
                  key={title}
                  title={title}
                  onClick={() => {
                    onClick();
                    setPage(0);
                  }}
                  sortValue={sortValue}
                />
              ))}
              <th></th>
            </KwTableRow>
          </KwTableHead>
          <KwTableBody>
            {apps?.map(
              ({
                applicationId,
                applicationVersion,
                contactedCountryCodes,
                deviceCount,
                // @ts-ignore
                appIconBase64,
                importantFindings,
                management,
                os,
                permissions,
                policyViolations,
                riskIndicatorCount,
                title,
                updatedDate,
                versions,
                // @ts-ignore
                otherVersions,
                // @ts-ignore
                isDenied,
                // @ts-ignore
                isAllowed,
              }) => {
                const localIconUrl = `data:image/png;base64,${appIconBase64}`;
                const numPreviousVersions = sumPreviousVersions(applicationVersion, otherVersions);
                // const totalDeviceCount = sumDeviceCounts(versions);
                return (
                  <TableRowLink
                    ariaLabel={`${title} row`}
                    key={applicationId + applicationVersion + os + riskIndicatorCount}
                    uniqueId={applicationId}
                    href={`/applications/application-details?appId=${applicationId}&appName=${title}&appVersion=${applicationVersion}&os=${os}`}
                  >
                    <KwTableCell selected={!!appNameOrder}>
                      <StyledAppContentWrapper>
                        {appIconBase64 ? (
                          <StyledIcon alt={`${applicationId} app icon`} src={localIconUrl} />
                        ) : (
                          <StyledIconPlaceholder alt={`${applicationId} placeholder app icon`} src="/icons/app-placeholder-60x60.svg" />
                        )}
                        <StyledAppContent>
                          <div>
                            <StyledAppTitle>{decodeURIComponent(title)}</StyledAppTitle>
                            {renderAppTag(isDenied, isAllowed, policyViolations, importantFindings)}
                          </div>
                          <div>{applicationId}</div>
                          <div>
                            <StyledIconWrapper>{platformIcon(os)}</StyledIconWrapper>
                            {applicationVersion}
                            {numPreviousVersions === 0 ? null : (
                              <StyledMoreVersions>&#43;{numPreviousVersions} more observed</StyledMoreVersions>
                            )}
                          </div>
                          <div>
                            <StyledIconWrapper>
                              <StyledCalendarIcon />
                            </StyledIconWrapper>
                            <StyledUpdatedDate>{dayjs(updatedDate).format('MM/DD/YY')}</StyledUpdatedDate>
                          </div>
                        </StyledAppContent>
                      </StyledAppContentWrapper>
                    </KwTableCell>
                    <KwTableCell>{appTypes[management] || ''}</KwTableCell>
                    {/* <KwTableCell>
                      {numPreviousVersions === 0 ? (
                        <div>{deviceCount}</div>
                      ) : (
                        <>
                          <div>{deviceCount} (this version)</div>
                          <div>{totalDeviceCount} (all versions)</div>
                        </>
                      )}
                    </KwTableCell> */}
                    <StyledCountriesTableCell>
                      <CountriesContactedCellContent
                        contactedCountryCodes={contactedCountryCodes}
                        highRiskCountries={highRiskCountries}
                        highRiskCountriesError={highRiskCountriesError}
                      />
                    </StyledCountriesTableCell>
                    <KwTableCell selected={!!numberOfIorsOrder}>{riskIndicatorCount}</KwTableCell>
                    <KwTableCell>{permissions || 0}</KwTableCell>
                    <KwTableCell>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          handlePopoverClick(e, applicationId, os);
                        }}
                      >
                        <StyledMoreVertIcon />
                      </IconButton>
                    </KwTableCell>
                    <KwTableCell>
                      <ApplicationsPopover
                        deniedApps={deniedApps}
                        trustedApps={trustedApps}
                        applicationId={applicationId}
                        applicationVersion={applicationVersion}
                        os={os}
                        name={title}
                        isOpen={popoverId === applicationId}
                        anchorEl={popoverAnchorEl}
                        handleClose={handlePopoverClose}
                        fetchDeniedApps={fetchDeniedApps}
                        fetchTrustedApps={fetchTrustedApps}
                        showDeniedApps={showDeniedApps}
                      />
                    </KwTableCell>
                  </TableRowLink>
                );
              },
            )}
          </KwTableBody>
        </KwTable>
        {total > 10 ? (
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
          </KwTablePaginationWrapper>
        ) : null}
      </div>
    </>
  );
}

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledAppContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px 10px 0;
`;

const StyledCountriesTableCell = styled(KwTableCell)`
  min-width: 200px;
`;

const AppIconStyles = css`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: block;
`;

const StyledIcon = styled(Avatar)`
  ${AppIconStyles}
`;

const StyledIconPlaceholder = styled('img')`
  ${AppIconStyles}
`;

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledDeniedIcon = styled(DeniedIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledTrustedIcon = styled(TrustedIcon)`
  font-size: 21px;
  margin: -6px 4px;
`;

const StyledTrustedTagText = styled('span')`
  color: #4caf50;
`;

const StyledWarningMessage = styled('span')`
  color: #d32f2f;
`;

const StyledAppContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledIconWrapper = styled('div')`
  display: inline-block;
  margin-right: 5px;
`;

const StyledAppleIcon = styled(Apple)`
  font-size: 12px;
  color: #a2aaad;
`;

const StyledAndroidIcon = styled(Android)`
  font-size: 12px;
  color: #a4c639;
`;

const StyledCalendarIcon = styled(CalendarToday)`
  font-size: 12px;
  color: #244c5a;
  vertical-align: middle;
`;

const StyledUpdatedDate = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

const StyledAppTitle = styled('div')`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  display: inline-block;
`;

const StyledMoreVersions = styled('span')`
  font-weight: 700;
  color: #244c5a;
  margin-left: 5px;
`;

const iconStyles = props => css`
  color: ${props.theme.palette.accent.primary};
`;

const StyledMoreVertIcon = styled(MoreVertIcon)`
  ${iconStyles}
`;
