

// Merge the values for some of the categories
export const transformAndCleanData = results => {
    const transformations = {
      Trojan: ['Trojan', 'Dropper', 'Downloader'],
      Malware: ['Malware'],
      Spyware: ['Spyware', 'Infostealer'],
      Backdoor: ['Backdoor'],
      Ransomware: ['Ransomware'],
      PUA: ['PUA'],
      Hacktool: ['Hacktool'],
      Dialer: ['Dialer'],
      Adware: ['Adware'],
    };

    return Object.entries(transformations).reduce((acc, [key, fields]) => {
      if (!results) {
        acc[key] = 0;
        return acc;
      }

      acc[key] = fields.reduce((sum, field) => sum + (results[field] ?? 0), 0);
      return acc;
    }, {});
  };