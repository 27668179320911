import { Avatar } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import { theme } from '../../../theme';
// components
import { KwSearchInput } from '../../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../../kw-ui-components/KwTable';
import { KwTableBody } from '../../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../../kw-ui-components/KwTableCell';
import { KwTableRow } from '../../../kw-ui-components/KwTableRow';
// pagination
import { KwPaginationRouter } from '../../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../../kw-ui-components/Pagination/useKwPagination';
import { KwTablePaginationWrapper } from '../../../kw-ui-components/Pagination/KwTablePaginationWrapper';

import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';
import { KwTableHead } from '../../../kw-ui-components/KwTableHead';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { KwColumnSortHeader } from '../../../kw-ui-components/KwColumnSortHeader';

import { fetchService, useFetchService } from '../../../utils/fetchService';
import { useAppManagementSortParams } from '../useAppManagementSortParams';
import { PageLoader } from '../../../PageLoader';
import { KwMultiSelect, useKwMultiSelect } from '../../../kw-ui-components/KwMultiSelect/KwMultiSelect';
import { ResetButton } from '../../shared/ResetButton';
import { getComparator } from '../../../utils/getComparator';
import { ManagedAppsTableAddAppPopover } from '../AppManagementPopovers';
import { createQueryString } from '../../../utils/createQueryString';
import { formatPlatform } from '../../../utils/formatPlatform';
import { httpRequest, sendHttpRequest } from '../../../utils/network.service';
import { useManagedApps } from '../ManagedApps/ManagedAppsTable';

export const useTrustedApps = (queryParams, accessToken) => {
  const queryParamString = createQueryString(queryParams);
  const { data: trustedAppsData, error: trustedAppsError, mutate } = useFetchService(`/list/trusted-apps?${queryParamString}`, accessToken);
  const isLoading = !trustedAppsData && !trustedAppsError;
  return { trustedAppsData, trustedAppsError, isLoading, mutate };
};

export default function TrustedAppsTable() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [vendorData, setVendorData] = useState([]);
  const [apps, setApps] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [addAppButtonAnchorEl, setAddAppButtonAnchorEl] = useState(null);
  const { page, pageSize, handleChangePage, handleChangeRowsPerPage, setPage } = useKwPagination(10);
  const {
    appNameOrder,
    toggleAppNameOrder,
    platformOrder,
    togglePlatformOrder,
    vendorOrder,
    toggleVendorOrder,
    versionOrder,
    appIdOrder,
    toggleAppIdOrder,
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder,
    orderBy,
    setOrderBy,
    resetToggles,
  } = useAppManagementSortParams(); 

  const [appIcons = {}, updateAppIcons] = useLocalStorage('appIcons', {});

  useEffect(() => {
    const fetchAppMetaData = async () => {
      try {
        if (apps?.length) {
          for (const app of apps) {
            const appIconKey = `${app.applicationId}-${app.os}`;

            if (appIcons[appIconKey]) continue;

            const queryParams = createQueryString({
              packageName: app.bundleId,
              platform: app.os,
              fields: 'iconLargeUrl',
              maxAge: '86400',
            });

            const res = await fetchService(`/application/app-metadata?${queryParams}`, accessToken);

            if (res?.iconLargeUrl && !appIcons[appIconKey]) {
              app.icon = res.iconLargeUrl;
              updateAppIcons((icons: { [key: string]: string }) => ({ ...icons, [appIconKey]: app.icon }));
              break;
            }
          }
        }
      } catch (error) {
        console.log('Error fetching app icon', error);
      }
    };

    fetchAppMetaData();
  }, [accessToken, appIcons, apps, updateAppIcons]);

  const platformSelectHook = useKwMultiSelect();
  const vendorSelectHook = useKwMultiSelect();

  // eslint-disable-next-line prefer-const
  let { trustedAppsData, trustedAppsError, isLoading, mutate } = useTrustedApps(
    {
      os: platformSelectHook.value.length > 1 ? '' : platformSelectHook.value,
    },
    accessToken,
  );

  const fetchDeviceCounts = async (trustedApps: any[]) => {
    const applications = trustedApps.map(app => ({
      applicationId: app.bundleId,
      os: app.os,
    }));

    const requestBody = { applications };

    try {
      const response = await httpRequest({
        path: `${process.env.DEVICE_SERVICE_URL}/application/device-counts`,
        method: 'POST',
        body: requestBody,
      });

      return response;
    } catch (error) {
      console.error('Error fetching device counts:', error);
      return error;
    }
  };

  // eslint-disable-next-line prefer-const
  let { managedAppsData, managedAppsError, mutate: mutateManagedApps } = useManagedApps({}, accessToken);

  const managedApps = managedAppsData ? managedAppsData || [] : [];

  useEffect(() => {
    const fetchData = async () => {
      const filteredApps = trustedAppsData ? trustedAppsData.trustedApps || [] : [];
      const query = searchQuery.toLowerCase();
      const filterConditions = searchQuery.length || vendorSelectHook.value.length;

      let filteredTrustedApps = filterConditions
        ? filteredApps
            .filter(
              item =>
                item.name.toLowerCase().includes(query) ||
                item.platform.toLowerCase().includes(query) ||
                item.vendorName.toLowerCase().includes(query) ||
                item.version.toLowerCase().includes(query) ||
                item.packageName.toLowerCase().includes(query),
            )
            .filter(item => {
              if (vendorSelectHook.value.length) {
                return vendorSelectHook.value.includes(item.vendorName);
              }
              return true;
            })
        : filteredApps;

      const { data } = await fetchDeviceCounts(filteredTrustedApps);
      filteredTrustedApps = filteredTrustedApps.map(app => {
        const deviceCountInfo = data.find(count => count.applicationId === app.bundleId);
        return {
          ...app,
          deviceInstallCount: deviceCountInfo ? deviceCountInfo.deviceCount : 0,
        };
      });

      setApps(filteredTrustedApps);
    };
    fetchData();
  }, [trustedAppsData, searchQuery, vendorSelectHook.value]);

  const total = apps?.length;
  const totalPageCount = Math.ceil(total / pageSize);

  const tableHeaders = [
    {
      title: 'App Name',
      onClick: toggleAppNameOrder,
      sortValue: appNameOrder,
    },
    {
      title: 'Platform',
      onClick: togglePlatformOrder,
      sortValue: platformOrder,
    },
    {
      title: 'Vendor',
      onClick: toggleVendorOrder,
      sortValue: vendorOrder,
    },
    {
      title: 'App ID',
      onClick: toggleAppIdOrder,
      sortValue: appIdOrder,
    },
    {
      title: '# of Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: 'Action',
      onClick: () => {},
      sortValue: null,
    },
  ];

  // column sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (appNameOrder) {
      setOrderBy('name');
    }

    if (platformOrder) {
      setOrderBy('platform');
    }

    if (vendorOrder) {
      setOrderBy('vendorName');
    }

    if (versionOrder) {
      setOrderBy('version');
    }

    if (appIdOrder) {
      setOrderBy('packageName');
    }

    if (numberOfDevicesOrder) {
      setOrderBy('deviceInstallCount');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [numberOfDevicesOrder, order, orderBy, setOrderBy, appNameOrder, platformOrder, vendorOrder, versionOrder, appIdOrder]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, setPage]);

  const platformSelectData = [
    { value: 'android', label: 'Android' },
    { value: 'ios', label: 'iOS' },
  ];

  const vendorSelectData = useRef([]);
  useEffect(() => {
    const appData = trustedAppsData ? trustedAppsData.trustedApps || [] : [];
    vendorSelectData.current = appData.map(({ vendorName }) => ({ value: vendorName, label: vendorName }));
    setVendorData([...new Map(vendorSelectData.current.map(item => [item.value, item])).values()]);
  }, [apps, trustedAppsData]);

  const resetFilterAndSorts = () => {
    resetToggles();
    setPage(0);
    platformSelectHook.reset();
    vendorSelectHook.reset();
    setSearchQuery('');
  };

  const handleDeleteTrustedApp = async (app: any) => {
    const successMessage = `Successfully deleted ${app.name}`;
    const errorMessage = `Error deleting ${app.name}`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps/${app.bundleId}/${app.os}`,
      method: 'DELETE',
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });

    await mutate();
  };

  const handleAddAppPopoverClick = event => {
    setAddAppButtonAnchorEl(addAppButtonAnchorEl ? null : event.currentTarget);
  };

  if (trustedAppsError) {
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <ThemeProvider theme={theme}>
        <StyledTrustedAppsTableInfoWrapper>
          <span>
            Configure the apps you want to be trusted in your environment. Trusted Apps will not trigger any high-level Issues or Policy
            Violations. Default Trusted Apps are popular apps from reputable vendors. Published Managed Apps are considered trusted
            automatically, and must be unpublished before they can be removed from this list.
          </span>
        </StyledTrustedAppsTableInfoWrapper>
        <StyledFilterWrapper>
          <KwMultiSelect
            label="Platform"
            items={platformSelectData}
            {...platformSelectHook}
            handleChange={(...args) => {
              platformSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <KwMultiSelect
            label="Vendor"
            items={vendorData}
            {...vendorSelectHook}
            handleChange={(...args) => {
              vendorSelectHook.handleChange(...args);
              setPage(0);
            }}
          />
          <ResetButton
            onClick={() => {
              resetFilterAndSorts();
            }}
          />
          <StyledKwButton endIcon={<KeyboardArrowDownIcon />} variant="filled" onClick={event => handleAddAppPopoverClick(event)}>
            Add Trusted App
          </StyledKwButton>
          <ManagedAppsTableAddAppPopover
            anchorEl={addAppButtonAnchorEl}
            setAnchorEl={setAddAppButtonAnchorEl}
            handleClose={event => handleAddAppPopoverClick(event)}
          />
        </StyledFilterWrapper>
        <StyledTableWrapper>
          <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search apps" />
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50, 100]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>
          <KwTable aria-label="Managed-Apps-Table">
            <KwTableHead>
              <KwTableRow>
                {tableHeaders.map(({ title, onClick, sortValue }) => (
                  <KwColumnSortHeader
                    key={title}
                    title={title}
                    onClick={() => {
                      onClick();
                      setPage(0);
                    }}
                    sortValue={sortValue}
                  />
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {[...apps]
                .sort(getComparator(order, orderBy))
                .slice(page * pageSize, (page + 1) * pageSize)
                .map(app => {
                  const localIconUrl = appIcons[`${app.bundleId}-${app.os}`];
                  const isPublishedManaged = managedApps?.some(
                    ({ packageName, platform, published }) => packageName === app.bundleId && platform === app.os && published,
                  );

                  return (
                    <KwTableRow key={app.bundleId} aria-label={`${app.name} row`}>
                      <StyledKwTableCell>
                        <StyledAvatar alt={`${app.name} app icon`} src={localIconUrl} />
                        <>
                          {app.name}
                          {isPublishedManaged && <StyledTag>Managed App</StyledTag>}
                        </>
                      </StyledKwTableCell>
                      <KwTableCell>{formatPlatform(app.os)}</KwTableCell>
                      <KwTableCell>{app.vendorName}</KwTableCell>
                      <KwTableCell>{app.bundleId}</KwTableCell>
                      <KwTableCell>{app.deviceInstallCount}</KwTableCell>
                      <KwTableCell>
                        <KwButton size="medium" disabled={isPublishedManaged} onClick={event => handleDeleteTrustedApp(app)}>
                          <DeleteOutlineIcon />
                        </KwButton>
                      </KwTableCell>
                    </KwTableRow>
                  );
                })}
            </KwTableBody>
          </KwTable>
          {total > 10 ? (
            <KwTablePaginationWrapper>
              <KwRowsPerPage
                rowsPerPageOptions={[10, 20, 50, 100]}
                pageSize={pageSize}
                page={page}
                totalNumberOfEntries={total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
            </KwTablePaginationWrapper>
          ) : null}
        </StyledTableWrapper>
      </ThemeProvider>
    </>
  );
}

const StyledTableWrapper = styled('div')`
  width: 100%;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledKwButton = styled(KwButton)`
  // align-self: flex-end;
  margin-left: auto;
  min-width: 110px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;

const StyledKwTableCell = styled(KwTableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledFilterWrapper = styled('div')`
  display: flex;
  column-gap: 16px;
  margin-bottom: 28px;
  margin-top: 28px;
  align-items: center;
`;

const StyledTrustedAppsTableInfoWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
`;

const StyledTag = styled('div')`
  border-radius: 4px;
  padding: 3px 7px;
  margin-left: 12px;
  background-color: #d9ebe5;
`;
