import { useState } from 'react';
import { Divider, Modal, Typography, styled, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { KwButton } from '../../../kw-ui-components/KwButton/KwButton';

export const ThreatTypeDistributionHelpModal = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleHelpIconClick = () => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setIsHelpModalOpen(false);
  };

  return (
    <>
      <StyledHelpIcon onClick={handleHelpIconClick}></StyledHelpIcon>
      <Modal open={isHelpModalOpen} aria-labelledby="tooltip-modal-title" aria-describedby="tooltips-modal" onClose={handleHelpModalClose}>
        <StyledBox>
          <Typography variant="h3Medium" id="tooltip-modal-title">
            Malicious Report
            <StyledCloseIcon onClick={handleHelpModalClose} />
          </Typography>

          <StyledDivider />

          <div id="tooltips-modal">
            <Typography variant="bodyRegular">
              Quokka's Malicious Report takes a behavior-based machine learning approach to determine how a given mobile application behaves
              compared to known types of malware. Similarity to a particular threat type is factored into a sum of all threat behavior
              examined, assigning the app an overall Malicious Score and behavioral designation of benign, suspicious or malicious. The
              Malicious Report is available for Android apps, with support for iOS apps coming soon.
            </Typography>
          </div>

          <StyledButtonsContainer>
            <KwButton variant="filled" onClick={handleHelpModalClose}>
              Got it
            </KwButton>
          </StyledButtonsContainer>
        </StyledBox>
      </Modal>
    </>
  );
};

const StyledBox = styled(Box)(() => ({
  padding: 16,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#fafafa',
  outline: 'none',
}));

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
});

const StyledButtonsContainer = styled('div')({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));
