import { ArrowBack } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Avatar, Box, Typography } from '@mui/material';
import _ from 'lodash';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { useFetchService } from '../../utils/fetchService';
import { createQueryString } from '../../utils/createQueryString';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { ManagedAppsInfoContext } from './AppManagementInfoContext';
// eslint-disable-next-line import/extensions
import { AppManagementProfileMode } from './enum/AppManagementKeyMaps.enum';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { useManagedApps } from './ManagedApps/ManagedAppsTable';
import { useRecentlyViewedApps } from './useRecentlyViewedApps';
import { PageLoader } from '../../PageLoader';

export const useManagedAppSearch = (queryParams, accessToken, shouldFetch) => {
  const queryParamString = createQueryString(queryParams);
  const {
    data: searchResults,
    error: searchResultsError,
    mutate,
  } = useFetchService(`/pdm/app-catalog?${queryParamString}`, accessToken, shouldFetch);

  const isLoading = !searchResults && !searchResultsError;

  return { searchResults, searchResultsError, isLoading, mutate };
};

export default function AppManagementSearch() {
  const navigate = useNavigate();

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');

  const [managedApps, setManagedApps] = useState({ results: [], numberOfPages: 0, pageNum: 1, pageSize: 40, totalResults: 0 });
  // const [mostPopularApps, setMostPopularApps] = useState([]);
  const [selected, setSelected] = useState([]);

  const isSelected = (packageName: string) => selected.indexOf(packageName) !== -1;

  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const { page, pageSize, handleChangeRowsPerPage, handleChangePage } = useKwPagination(10);

  const { platform, searchHistory, setPlatform, setMode, setApp, setSearchHistory } = useContext(ManagedAppsInfoContext);

  const { recentlyViewedApps, updateRecentlyViewedApps } = useRecentlyViewedApps();

  const tableHeaders = [{ title: 'App Name' }, { title: 'Vendor' }];

  const searchBarPlaceholder = platform === 'ios' ? 'Search the Apple App Store' : 'Search the Google Play App Store';

  // widget data
  const { managedAppsData } = useManagedApps(
    {
      os: platform,
    },
    accessToken,
  );

  const queryParamString = createQueryString({
    os: platform,
  });
  const { data: deniedAppsData } = useFetchService(`/list/denied-apps?${queryParamString}`, accessToken);
  const { data: trustedAppsData } = useFetchService(`/list/trusted-apps?${queryParamString}`, accessToken);

  const renderTag = ({ app }) => {
    if (!app) return <></>;
    if (managedAppsData?.some(({ packageName, published }) => packageName === app.packageName && published)) {
      return <StyledTag style={{ backgroundColor: '#D9EBE5' }}>Managed App</StyledTag>;
    }
    if (deniedAppsData?.deniedApps?.some(({ bundleId }) => bundleId === app.packageName)) {
      return <StyledTag style={{ backgroundColor: '#FCD7CE' }}>Denied App</StyledTag>;
    }
    if (trustedAppsData?.trustedApps?.some(({ bundleId }) => bundleId === app.packageName)) {
      return <StyledTag style={{ backgroundColor: '#D3E9F9' }}>Trusted App</StyledTag>;
    }
    return <></>;
  };

  // search data
  const { searchResults, searchResultsError, isLoading } = useManagedAppSearch(
    {
      searchText: debouncedSearchQuery,
      pageNum: String(page + 1),
      pageSize: String(pageSize),
      os: platform,
    },
    accessToken,
    debouncedSearchQuery.length,
  );

  const total = managedApps.totalResults;
  const totalPageCount = Math.ceil(total / pageSize);

  // memoize search data
  const apps = useMemo(() => {
    return searchResults || [];
  }, [searchResults]);

  // memoizes org managed apps data for recently viewed widget update function
  const widgetAppData = useMemo(() => {
    return managedAppsData || [];
  }, [managedAppsData]);

  // FIXME - currently there is no backend route to support this
  // useEffect(() => {
  //   const topFiveMostPopularManagedApps = widgetAppData.sort((a, b) => a.deviceInstallCount - b.deviceInstallCount).slice(0, 5);
  //   setMostPopularApps(topFiveMostPopularManagedApps);
  // }, [widgetAppData]);

  useEffect(() => {
    if (!platform) navigate('/app-management');
  }, [platform, navigate]);

  // display search results after search query is entered
  useEffect(() => {
    setManagedApps(apps);
  }, [apps, searchQuery, searchResults]);

  // clear selected if search bar is updated
  useEffect(() => {
    setSelected([]);
  }, [searchQuery]);

  // sets search input value from previous search if user clicks "Back to Search" or "Cancel" button on '/add' page (AppManagementProfilePage.tsx)
  useEffect(() => {
    setSearchQuery(searchHistory);
  }, [searchHistory]);

  const updateRecentlyViewed = latestApp => {
    const {
      description: viewedDescription,
      minimumOsVersion: viewedMinimumOsVersion,
      name: viewedName,
      packageName: viewedPackageName,
      platform: viewedPlatform,
      vendorName: viewedVendorName,
    } = latestApp;

    const alreadyExists = data =>
      data.some(({ description, minimumOsVersion, name, packageName, platform: appPlatform, vendorName }) => {
        return _.isEqual(
          Object.values({
            viewedDescription,
            viewedMinimumOsVersion,
            viewedName,
            viewedPackageName,
            viewedPlatform,
            viewedVendorName,
          }),
          Object.values({
            description,
            minimumOsVersion,
            name,
            packageName,
            appPlatform,
            vendorName,
          }),
        );
      });

    const isAlreadySaved = alreadyExists(widgetAppData);
    const isRecentlyViewed = alreadyExists(recentlyViewedApps);

    if (!isAlreadySaved && !isRecentlyViewed) {
      updateRecentlyViewedApps(recentlyViewedApps.length ? [latestApp, ...recentlyViewedApps.splice(0, 4)] : [latestApp]);
    }
  };

  if (searchResultsError) {
    console.error(searchResultsError);
    return <div>failed to load</div>;
  }

  return (
    <KwContainer>
      <StyledKwButton
        variant="light"
        startIcon={<ArrowBack />}
        onClick={() => {
          navigate('/app-management');
          setSearchHistory('');
        }}
      >
        Back to App Management
      </StyledKwButton>
      <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder={searchBarPlaceholder} />
      {searchQuery && isLoading && <PageLoader />}
      {managedApps.results?.length > 0 ? (
        <>
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
          </KwTablePaginationWrapper>
          <KwTable aria-label="Managed-Apps-Catalog">
            <KwTableHead>
              <KwTableRow>
                {tableHeaders.map(({ title }) => (
                  <StyledColumnHeader key={title}>{title}</StyledColumnHeader>
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {managedApps.results?.map(app => {
                const isItemSelected = isSelected(app.packageName);
                return (
                  <StyledTableRow
                    onClick={() => {
                      setSelected(app.packageName);
                      setApp(app);
                      setSearchHistory(searchQuery);
                      setMode(AppManagementProfileMode.ADD);
                      updateRecentlyViewed(app);
                      navigate('/app-management/add');
                    }}
                    key={app.packageName + app.version}
                    selected={isItemSelected}
                  >
                    <StyledKwTableCell>
                      <StyledAvatar src={app.iconUrl} />
                      <>
                        {app.name}
                        {renderTag({ app })}
                      </>
                    </StyledKwTableCell>
                    <KwTableCell>{app.vendorName}</KwTableCell>
                  </StyledTableRow>
                );
              })}
            </KwTableBody>
          </KwTable>
          <KwTablePaginationWrapper>
            <KwRowsPerPage
              rowsPerPageOptions={[10, 20, 50]}
              pageSize={pageSize}
              page={page}
              totalNumberOfEntries={total}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
          </KwTablePaginationWrapper>
        </>
      ) : (
        <StyledWidgetContainer>
          {/* {mostPopularApps.length > 0 && (
            <>
              <Typography variant="h5Light">Most frequently added apps</Typography>
              <StyledBox>
                {mostPopularApps.map(app => {
                  const { name, iconUrl, managedAppId } = app;
                  return (
                    <StyledWidgetAppWrapper
                      key={name}
                      onClick={() => {
                        setApp(app);
                        setMode(ManagedAppsProfileMode.EDIT);
                        setPlatform(app.platform);
                        navigate(`/managed-apps/edit/${managedAppId}`);
                      }}
                    >
                      <StyledWidgetAvatar src={iconUrl} />
                      <StyledWidgetName>{name}</StyledWidgetName>
                    </StyledWidgetAppWrapper>
                  );
                })}
              </StyledBox>
            </>
          )} */}
          {!searchQuery && recentlyViewedApps.length > 0 && (
            <>
              <Typography variant="h5Light">Recently viewed apps</Typography>
              <StyledBox>
                {recentlyViewedApps.map(app => {
                  const { name, iconUrl } = app;
                  return (
                    <StyledWidgetAppWrapper
                      key={name}
                      onClick={() => {
                        setApp(app);
                        setMode(AppManagementProfileMode.ADD);
                        setPlatform(app.platform);
                        navigate(`/app-management/add`);
                      }}
                    >
                      <StyledWidgetAvatar src={iconUrl} />
                      <StyledWidgetName>{name}</StyledWidgetName>
                    </StyledWidgetAppWrapper>
                  );
                })}
              </StyledBox>
            </>
          )}
        </StyledWidgetContainer>
      )}
    </KwContainer>
  );
}

const StyledAvatar = styled(Avatar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const StyledKwTableCell = styled(KwTableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledColumnHeader = styled(KwTableCell)`
  font-weight: 700;
`;

const StyledKwButton = styled(KwButton)`
  margin-bottom: 10px;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableRow = styled(KwTableRow)<{ selected?: boolean }>`
  ${KwTableBody} & {
    background-color: ${props => (props.selected ? '#EEEEEE' : 'transparent')};
  }
`;

const StyledWidgetContainer = styled('div')`
  margin: 20px 0;
`;

const StyledBox = styled(Box)`
  margin: 20px 0;
  display: flex;
`;

const StyledWidgetAppWrapper = styled(Box)`
  margin: 0 16px;
  cursor: pointer;
`;

const StyledWidgetAvatar = styled(Avatar)`
  height: 100px;
  width: 100px;
`;

const StyledWidgetName = styled(Typography)`
  max-width: 100px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-top: 8px;
  text-align: center;
`;

const StyledTag = styled('div')(() => ({
  borderRadius: '4px',
  padding: '3px 7px',
  marginLeft: '12px',
}));
