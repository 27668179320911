import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRelativePosition, toLineHeight } from 'chart.js/helpers';
import { styled } from '@mui/material/styles';

import { Doughnut } from 'react-chartjs-2';
import _ from 'lodash';

import { QTypography } from '@kw/quokka-ui';
import { BackgroundDoughnut } from './MaliciousScoreBgDoughnut';

ChartJS.register(ArcElement, Tooltip, Legend);

export const MaliciousScoreDoughnut = props => {
  // Based on https://stackoverflow.com/a/43026361
  const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw(chart) {
      if (chart.config.type === 'doughnut' && _.get(chart, 'data.datasets[0].data')) {
        const { ctx, chartArea } = chart;
        const { controller } = chart.getDatasetMeta(0);

        // Get options from the center object in options
        const totalCount = _.sum(chart.data.datasets[0].data);
        const definedValue = props.value !== undefined && props.value !== null;
        const displayValue = definedValue ? props.value : totalCount;
        const isNoData = displayValue === -1;

        // Adjust the font size conditionally
        let numberFontSize;
        let titleFontSize;
        let centerYAdjustment;

        if (isNoData || definedValue) {
          numberFontSize = 2.5;
          centerYAdjustment = 1.3;
          ctx.font = `700 ${numberFontSize}em Nunito Sans`; // Regular font for number
        } else {
          numberFontSize =
            (Math.min(parseFloat((chart.height / 60).toFixed(2)), controller.innerRadius / 25) * 3) /
            Math.max(String(displayValue).length, 2);

          titleFontSize = Math.min(parseFloat((chart.height / 140).toFixed(2)), controller.innerRadius / 60);
          centerYAdjustment = 2; // Default adjustment for TOTAL text
          ctx.font = `${numberFontSize}em Nunito Sans`; // Regular font for total
        }

        const lineHeight = titleFontSize * 10;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = (chartArea.left + chartArea.right) / 2;
        let centerY = (chartArea.top + chartArea.bottom) / 2;

        ctx.fillStyle = '#000';

        // Display "N/A" if value is -1
        if (isNoData) {
          ctx.fillText('N/A', centerX, centerY);
        } else {
          ctx.fillText(displayValue, centerX, centerY + toLineHeight(props.options.plugins.title.font.size, 1));
          ctx.font = '14px Nunito Sans';
          const textHeight = toLineHeight(ctx.font, 14); // 14 pixels from ctx.font
          ctx.fillText(props.text, centerX, chartArea.bottom - textHeight + (textHeight / 2)); // place it at the bottom

          // Move down and add subtext in smaller font if not definedValue
          if (!definedValue) {
            ctx.font = `${titleFontSize}em Nunito Sans`;
            centerY += lineHeight * 4; // Move down the TOTAL text
            ctx.fillText('TOTAL', centerX, centerY);
          }
        }
      }
    },
  };

  const passedLegend = props?.options?.legend;

  const parsedLegend = {
    ...passedLegend,
    display: false,
    position: 'left',
    labels: {
      ...passedLegend?.labels,
      usePointStyle: true,
      pointStyle: 'circle',
      color: '#000000',
      font: {
        size: 14,
        style: 'normal',
        family: 'Nunito Sans',
      },
      generateLabels: chart => {
        const { datasets } = chart.data;

        return datasets[0].data.map((data, i) => ({
          text: `${chart.data.labels[i]} ${props.hideNumbers ? '' : `(${data})`}`,
          fillStyle: datasets[0].backgroundColor[i],
          hidden: !chart.getDataVisibility(i),
          index: i,
        }));
      },
    },
  };

  // If no options are passed we still want to force the legend styling for our design.
  props = {
    ...props,
    options: {
      ...props?.options,
      ...(props?.options?.maintainAspectRatio ?? { aspectRatio: 2 }),

      plugins: {
        ...props?.options?.plugins,
        legend: parsedLegend,
      },
    },
    plugins: [centerTextPlugin],
  };
  return (
    <StyledContainer>
      <StyledGaugeContainer>
        <Doughnut {...props} redraw={true} />
        <div>
          <div style={{ position: 'absolute', color: '#757575', bottom: '-11px', left: '-25px' }}>
            <QTypography variant="body">0</QTypography>
          </div>
          <div style={{ position: 'absolute', color: '#757575', bottom: '-11px', right: '-42px' }}>
            <QTypography variant="body">100</QTypography>
          </div>

          <div style={{ position: 'absolute', color: '#757575', top: '-22px', left: '62px' }}>
            <QTypography variant="body">40</QTypography>
          </div>

          <div style={{ position: 'absolute', color: '#757575', top: '-5px', right: '30px' }}>
            <QTypography variant="body">70</QTypography>
          </div>
        </div>
      </StyledGaugeContainer>
      <div style={{ position: 'absolute', zIndex: 1, bottom: '0px', maxWidth: '265px', margin: '0 auto' }}>
        <BackgroundDoughnut />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled('div')`
  display: grid;
  place-items: center;
  position: relative;
  margin-top: 20px;
`;

const StyledGaugeContainer = styled('div')`
  position: relative;
  z-index: 2;
  max-width: 250px;
`;
